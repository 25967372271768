import React from 'react'
import { Container, Segment, Card, Header } from 'semantic-ui-react'
import { graphql } from 'gatsby'
import { withTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'

import Layout from '../components/Layouts/PageLayout'
import PageHeader from '../components/Items/PageHeader'
import { getFluidImage, getRawImage } from '../utils/images'

const Team = ({ data, t, pageContext }) => {
  const teams = data.allFirestoreTeam.edges
  const teamImage = data.firestoreImage

  return (
    <div>
      <Helmet>
        <title>{t('team.header.title') + t('htmlTitleSuffix')}</title>

        <meta name='description' content={t('team.header.body')} />
        <meta name='image' content={getRawImage({ cloudinary: teamImage })} />

        <meta property='og:title' content={t('team.header.title')} />
        <meta property='og:description' content={t('team.header.body')} />
        <meta
          property='og:image'
          content={getRawImage({ cloudinary: teamImage })}
        />

        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:creator' content='tourasia' />
        <meta name='twitter:title' content={t('team.header.title')} />
        <meta name='twitter:description' content={t('team.header.body')} />
        <meta
          name='twitter:image'
          content={getRawImage({ cloudinary: teamImage })}
        />
      </Helmet>

      <Layout pageContext={pageContext}>
        <Img
          fluid={getFluidImage({ cloudinary: teamImage.cloudinary, ar: 2 })}
          alt={teamImage.alt || teamImage.title}
        />

        <PageHeader
          title={t('team.header.title')}
          subtitle={t('team.header.body')}
        />

        <Container>
          <Segment vertical basic padded='very'>
            <Card.Group itemsPerRow={3} stackable>
              {teams.map(
                ({
                  node: { department, title, alt, name, countries, images }
                }) => (
                  <Card key={name} className='lightgrey inverted'>
                    <Img
                      fluid={getFluidImage({
                        cloudinary: images && images[0].cloudinary,
                        maxWidth: 768,
                        ar: 1.2
                      })}
                      alt={images && (images[0].alt || images[0].title)}
                    />

                    <Card.Content>
                      <Header as='h3' color='red' style={{ marginBottom: 0 }}>
                        {name}
                      </Header>
                      <Card.Description>
                        <strong>{department}</strong>
                      </Card.Description>
                      <Card.Meta>{title}</Card.Meta>
                      <Card.Description>
                        <strong>{t('team.card.countries')}</strong> {countries}
                      </Card.Description>
                    </Card.Content>
                  </Card>
                )
              )}
            </Card.Group>
          </Segment>
        </Container>
      </Layout>
    </div>
  )
}

export default withTranslation()(Team)

export const pageQuery = graphql`
  query TeamQuery($lang: String!) {
    allFirestoreTeam(
      filter: { lang: { eq: $lang } }
      sort: { fields: [jsonOrder] }
    ) {
      edges {
        node {
          lang
          title
          name
          department
          countries
          images {
            cloudinary
            title
            alt
          }
          jsonOrder
          collection
        }
      }
    }
    firestoreImage(page: { eq: "team" }) {
      id
      cloudinary
      page
      title
      alt
    }
  }
`
